import BookingDiscountCreateView from './view/create';
import './css/create.css';

export default function BookingDiscountCreate() {
    return (
        <>
            <BookingDiscountCreateView />
        </>
    );
}
