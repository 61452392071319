import { Box, Button, Container, TextField } from '@mui/material';
import { useState } from 'react';

export default function Repassword() {
    const [newPassword, setNewPassword] = useState('');
    const [newRePassword, setNewRePassword] = useState('');

    const handleChange = () => {};

    return (
        <>
            <Container maxWidth="xs">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                    }}
                >
                    <h1>
                        <center>비밀번호를 변경해 주세요</center>
                    </h1>
                    <p>
                        비밀번호는 영문, 숫자, 특수문자 중 2종류 이상 조합하여
                        10자리 이상으로 만들어 주세요
                    </p>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="신규 비밀번호"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="비밀번호 확인"
                        type="password"
                        value={newRePassword}
                        onChange={(e) => setNewRePassword(e.target.value)}
                    />
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ mt: 2, mb: 2 }}
                        onClick={handleChange}
                    >
                        변경
                    </Button>
                </Box>
            </Container>
        </>
    );
}
