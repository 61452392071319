import { useCallback } from 'react';
import useAxios from './use-axios';

const usePostData = (baseURL) => {
    const { request, loading, error } = useAxios(baseURL);

    const postData = useCallback(
        (url, data = {}, onSuccess) => {
            request({
                method: 'post',
                url,
                data,
                onSuccess: (response) => {
                    if (response.success) {
                        if (onSuccess) onSuccess(response.data);
                    } else {
                        alert(response.message || '수정 중 오류가 발생했습니다.');
                    }
                },
                onError: (err) => {
                    alert(`Error: ${err.message}`);
                },
            });
        },
        [request]
    );

    return { loading, error, postData };
};

export default usePostData;
