import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

import { visuallyHidden } from '../../golfInformation/view/utils';

// ----------------------------------------------------------------------

export default function BookingTableHead({
    order,
    orderBy,
    rowCount,
    headLabel,
    numSelected,
    onRequestSort,
    onSelectAllClick,
}) {
    const onSort = (property) => (event) => {
        onRequestSort(event, property);
    };

    // console.log(
    //     'headLabel: ',
    //     headLabel.filter((element) => element !== undefined)
    // );

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell> */}

                {headLabel
                    .filter((element) => element !== undefined)
                    .map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align || 'center'}
                            sortDirection={
                                orderBy === headCell.id ? order : false
                            }
                            sx={{
                                width: headCell.width,
                                minWidth: headCell.minWidth,
                                fontWeight: 'bold',
                                fontSize: '16px',
                                padding: '16px 8px',
                            }}
                        >
                            <TableSortLabel
                                hideSortIcon
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : 'asc'
                                }
                                onClick={onSort(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box sx={{ ...visuallyHidden }}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}

BookingTableHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    rowCount: PropTypes.number,
    headLabel: PropTypes.array,
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func,
    onSelectAllClick: PropTypes.func,
};
