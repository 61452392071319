import { useState } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

export default function BookingTableRow({
    selected,
    index,
    editIndex,
    handleClick,
    rowValue,
}) {
    const [open, setOpen] = useState(null);

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    return (
        <>
            <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
                {rowValue
                    .filter((element) => element !== undefined)
                    .map((value, cellIndex) => (
                        <TableCell
                            align="center"
                            key={cellIndex}
                            sx={{ padding: '16px 8px' }}
                        >
                            {value}
                        </TableCell>
                    ))}
            </TableRow>
        </>
    );
}

BookingTableRow.propTypes = {
    handleClick: PropTypes.func,
    selected: PropTypes.any,
    editIndex: PropTypes.number,
    rowValue: PropTypes.any,
};
