import { useState, useCallback } from 'react';
import axios from 'axios';

const useAxios = (baseURL = process.env.REACT_APP_API_URL) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    // const [data, setData] = useState(null);

    const axiosInstance = axios.create({
        baseURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
        },
    });

	const request = useCallback(
        async ({ method = 'get', url, data = {}, params = {}, onSuccess, onError }) => {
            setLoading(true);
            setError(null);
            try {
                const response = await axiosInstance({
                    method,
                    url,
                    data,
                    params,
                });

                // 성공 시 콜백 실행
                if (onSuccess) onSuccess(response.data);
            } catch (err) {
                setError(err);
                // 실패 시 콜백 실행
                if (onError) onError(err);
            } finally {
                setLoading(false);
            }
        },
        [axiosInstance]
    );

	return { loading, error, request };

    // const requestData = useCallback(
    //     async (method, url, options = {}) => {
    //         setLoading(true);
    //         setError(null);

	// 		const token = localStorage.getItem('auth-token');

	// 		// headers 설정: 기존 headers가 있다면 추가, 없으면 새로 생성
    //         const headers = {
    //             ...(options.headers || {}), // 기존의 headers가 있으면 포함
    //             Authorization: `Bearer ${token}`, // Authorization 헤더 추가
    //         };

    //         try {
    //             const response = await axiosInstance({
    //                 method,
    //                 url,
    //                 ...options,
	// 				headers,
    //             });
    //             setData(response.data);
    //         } catch (err) {
    //             setError(err);
    //         } finally {
    //             setLoading(false);
    //         }
    //     },
    //     [axiosInstance]
    // );

    // const getData = (url, params = {}) => {
    //     return requestData('GET', url, { params });
    // };

    // const postData = (url, payload = {}) => {
    //     return requestData('POST', url, { data: payload });
    // };

    // return { data, loading, error, getData, postData };
};

export default useAxios;
