import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const authState = atom({
    key: 'authState', // 고유한 key 값 설정
    default: {
        isLoggedIn: false,
        user: null,
        token: null,
    },
	effects_UNSTABLE: [persistAtom],
});
