import { Checkbox } from '@mui/material';
import { useState } from 'react';

export default function BenefitCheckBox({ id, text, checkItemHandler }) {
    const [checked, setChecked] = useState(false); // 체크 여부 판단

    const checkHandled = ({ target }) => {
        setChecked(!checked);
        checkItemHandler(target.value, target.checked);
    };

    return (
        <Checkbox
            id={id}
            value={text}
            checked={checked}
            onChange={(e) => checkHandled(e)}
        />
        // {/* <label>
        //     <input
        //         id={id}
        //         type="checkbox"
        //         checked={checked}
        //         onChange={(e) => checkHandled(e)}
        //     />
        //     {text}
        // </label> */}
    );
}
