import { Box, ListItemButton, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { NAV } from './config-layout';
import Scrollbar from '../component/scrollbar/scrollbar';
import { usePathname } from '../routes/hooks';

export default function Nav() {
    const pathname = usePathname().split('/')[1];

    // const active = item.path === pathname;

    return (
        <>
            <Box
                sx={{
                    flexShrink: { lg: 0 },
                    width: { lg: NAV.WIDTH },
                }}
            >
                <Box
                    width={280}
                    height={'100%'}
                    position={'fixed'}
                    sx={{
                        position: 'fixed',
                        borderRight: '1px solid #e4e4e4',
                    }}
                >
                    <Scrollbar
                        sx={{
                            height: 1,
                            '& .simplebar-content': {
                                height: 1,
                                display: 'flex',
                                flexDirection: 'column',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                my: 3,
                                mx: 1.5,
                                py: 2,
                                px: 2.5,
                                fontSize: 20,
                                display: 'flex',
                                borderRadius: 1.5,
                                alignItems: 'center',
                                // bgcolor: (theme) =>
                                //     alpha(theme.palette.grey[500], 0.12),
                            }}
                        >
                            <Link
                                to={'/'}
                                style={{
                                    textDecoration: 'none',
                                    color: '#333',
                                }}
                            >
                                HBMP 부킹 관리자
                            </Link>
                        </Box>
                        <Stack component={'nav'} spacing={0.5} sx={{ px: 2 }}>
                            <Link
                                to={'bookingdiscount'}
                                style={{ textDecoration: 'none' }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 44,
                                        borderRadius: 0.75,
                                        // typography: 'body2',
                                        color: 'text.secondary',
                                        textTransform: 'capitalize',
                                        fontWeight: 'fontWeightMedium',
                                        ...('bookingdiscount' === pathname && {
                                            color: 'primary.main',
                                            fontWeight: 'fontWeightSemiBold',
                                            bgcolor: (theme) =>
                                                alpha(
                                                    theme.palette.primary.main,
                                                    0.08
                                                ),
                                            '&:hover': {
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.16
                                                    ),
                                            },
                                        }),
                                    }}
                                >
                                    <Box component={'span'}>할인부킹 관리</Box>
                                </ListItemButton>
                            </Link>
                            <Link
                                to={'bookingdiscountrsvps'}
                                style={{ textDecoration: 'none' }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 44,
                                        borderRadius: 0.75,
                                        // typography: 'body2',
                                        color: 'text.secondary',
                                        textTransform: 'capitalize',
                                        fontWeight: 'fontWeightMedium',
                                        ...('bookingdiscountrsvps' === pathname && {
                                            color: 'primary.main',
                                            fontWeight: 'fontWeightSemiBold',
                                            bgcolor: (theme) =>
                                                alpha(
                                                    theme.palette.primary.main,
                                                    0.08
                                                ),
                                            '&:hover': {
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.16
                                                    ),
                                            },
                                        }),
                                    }}
                                >
                                    <Box component={'span'}>할인부킹 예약 관리</Box>
                                </ListItemButton>
                            </Link>
                            <Link
                                to={'golfinfo'}
                                style={{ textDecoration: 'none' }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 44,
                                        borderRadius: 0.75,
                                        // typography: 'body2',
                                        color: 'text.secondary',
                                        textTransform: 'capitalize',
                                        fontWeight: 'fontWeightMedium',
                                        ...('golfinfo' === pathname && {
                                            color: 'primary.main',
                                            fontWeight: 'fontWeightSemiBold',
                                            bgcolor: (theme) =>
                                                alpha(
                                                    theme.palette.primary.main,
                                                    0.08
                                                ),
                                            '&:hover': {
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.16
                                                    ),
                                            },
                                        }),
                                    }}
                                >
                                    <Box component={'span'}>골프장 관리</Box>
                                </ListItemButton>
                            </Link>
                            <Link
                                to={'golfacount'}
                                style={{ textDecoration: 'none' }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 44,
                                        borderRadius: 0.75,
                                        // typography: 'body2',
                                        color: 'text.secondary',
                                        textTransform: 'capitalize',
                                        fontWeight: 'fontWeightMedium',
                                        ...('golfacount' === pathname && {
                                            color: 'primary.main',
                                            fontWeight: 'fontWeightSemiBold',
                                            bgcolor: (theme) =>
                                                alpha(
                                                    theme.palette.primary.main,
                                                    0.08
                                                ),
                                            '&:hover': {
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.16
                                                    ),
                                            },
                                        }),
                                    }}
                                >
                                    <Box component={'span'}>
                                        골프장 계정관리
                                    </Box>
                                </ListItemButton>
                            </Link>
                        </Stack>
                    </Scrollbar>
                </Box>
            </Box>
        </>
    );
}
