import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
    Alert,
    Box,
    Button,
    Collapse,
    Container,
    TextField,
} from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authState } from '../recoil/authState';
// import LoginForm from '../component/LoginForm';

export default function Login() {
    const auth = useRecoilValue(authState);
    const setAuth = useSetRecoilState(authState);

    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const [id, setId] = useState('');
    const [password, setPassword] = useState('');

    const [errorAlert, setErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // useEffect(() => {
    //     console.log('useEffect');
    //     if (localStorage.getItem('auth-token')) {
    //         navigate('/');
    //     }
    // });

    const handleLogin = () => {
        // console.log(`Username: ${id}, Password: ${password}`);

        axios
            .post(`${apiUrl}/authClubAdmin`, {
                // email: id,
                id,
                password,
            })
            .then((response) => {
                console.log(response);
                const data = response.data;
                console.log(data);
                if (data.success) {
                    localStorage.setItem('auth-token', data.token);
                    localStorage.setItem('me', JSON.stringify(data.user));

                    setAuth({
                        isLoggedIn: true,
                        user: data.user,
                        token: data.token,
                    });

                    navigate('/');
                } else {
                    localStorage.clear();
                    setErrorMessage(data.message);
                    setErrorAlert(true);
                    //   setMessage('Invalid username or password');
                }
            })
            .catch((error) => {
                // setMessage('Error occurred while logging in');
            });
    };
    return auth.isLoggedIn ? (
        <Navigate to={'/'} />
    ) : (
        <>
            <Container maxWidth="xs">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                    }}
                >
                    <h1>
                        <center>단체부킹 관리 로그인</center>
                    </h1>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="id"
                        value={id}
                        onChange={(e) => {
                            setId(e.target.value);
                            setErrorAlert(false);
                        }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setErrorAlert(false);
                        }}
                    />
                    <Box>
                        <Collapse in={errorAlert}>
                            <Alert
                                variant="filled"
                                severity="error"
                                sx={{ m: 2 }}
                            >
                                {errorMessage}
                            </Alert>
                        </Collapse>
                    </Box>
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ mt: 2, mb: 2 }}
                        onClick={handleLogin}
                    >
                        로그인
                    </Button>
                </Box>
            </Container>
        </>
    );
}
