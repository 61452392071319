import { StatusProvider } from './contexts/statusContext';
import Router from './routes/sections';
import ThemeProvider from './theme';

export default function App() {
    return (
        <StatusProvider>
            <ThemeProvider>
                <Router />
            </ThemeProvider>
        </StatusProvider>
    );
}
