import React from 'react';
import { useStatus } from '../contexts/statusContext';

const StatusBox = ({ status, type = 'box' }) => {
    const statusData = useStatus();
    const statusInfo = statusData.find((item) => item.value === status);

    if (!statusInfo) {
        return null;
    }

    const { text, bgColor, textColor } = statusInfo;

    if (type === 'text') {
        return <span>{text}</span>;
    }

    const boxStyle = {
        backgroundColor: bgColor,
        color: textColor,
        marginBottom: '8px',
        padding: '8px',
        textAlign: 'center',
        // margin: '10px',
        // borderRadius: '5px',
        // display: 'inline-block'
    };

    return <div style={boxStyle}>{text}</div>;
};

export default StatusBox;
