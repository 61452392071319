import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import Header from './header';
import Nav from './nav';
import Main from './main';

export default function Layout({ children }) {
    return (
        <>
            <Header />
            <Box
                sx={{
                    minHeight: 1,
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                }}
            >
                <Nav />
                <Main>{children}</Main>
            </Box>
        </>
    );
}

Layout.prototype = {
    children: PropTypes.node,
};
