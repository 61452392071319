import React from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Modal,
} from '@mui/material';

const ModalConfirm = ({
    open,
    onClose,
    title,
    content,
    closeText,
    successText,
    onCloseClick,
    onSuccessClick,
}) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Container
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#e4e4e4',
                    padding: '0',
                    borderRadius: '0',
                    width: '500px',
                    overflow: 'auto',
                }}
            >
                <Card>
                    <CardHeader title={title} />
                    <CardContent>{content}</CardContent>
                    <CardActions
                        sx={{
                            ml: 1,
                            mb: 2,
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="inherit"
                            size="large"
                            sx={{ width: '50%' }}
                            onClick={onCloseClick}
                        >
                            {closeText}
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            size="large"
                            sx={{ width: '50%' }}
                            onClick={onSuccessClick}
                        >
                            {successText}
                        </Button>
                    </CardActions>
                </Card>
            </Container>
        </Modal>
    );
};

export default ModalConfirm;
