import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './global.css';
// import App from './App';

import {
    BrowserRouter,
    Outlet,
    RouterProvider,
    createBrowserRouter,
} from 'react-router-dom';

import Home from './pages/dashBoard/Home';
import BookingDiscount from './pages/bookingDiscount';
import Login from './pages/Login';
import Repassword from './pages/Repassword';
import Layout from './layouts';
import GolfInfomation from './pages/golfInformation';
import GolfAcount from './pages/golfAccount';
import ThemeProvider from './theme';
import BookingDiscountCreate from './pages/bookingDiscount/create';
import GolfInfomationModify from './pages/golfInformation/modify';
import BookingDiscountReservation from './pages/bookingDiscount/reservation';
import { StatusProvider } from './contexts/statusContext';

import { RecoilRoot } from 'recoil';
import App from './App';

const router = createBrowserRouter([
    {
        path: '/',
        // element: <Home />,
        element: (
            <Layout>
                <Suspense>
                    <Outlet />
                </Suspense>
            </Layout>
        ),
        children: [
            { element: <Home />, index: true },
            { path: 'bookingdiscount', element: <BookingDiscount /> },
            {
                path: 'bookingdiscount/create/:id',
                element: <BookingDiscountCreate />,
            },
            {
                path: 'bookingdiscountrsvps',
                element: <BookingDiscountReservation />,
            },
            { path: 'golfinfo', element: <GolfInfomation /> },
            { path: 'golfinfo/modify/:id', element: <GolfInfomationModify /> },
            { path: 'golfacount', element: <GolfAcount /> },
        ],
    },
    {
        path: 'login',
        element: <Login />,
    },
    {
        path: 'repassword',
        element: <Repassword />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <RecoilRoot>
        <BrowserRouter>
            <Suspense>
                <App />
            </Suspense>
        </BrowserRouter>
    </RecoilRoot>
);

// root.render(
//     <StatusProvider>
//         <ThemeProvider>
//             <RouterProvider router={router} />
//         </ThemeProvider>
//     </StatusProvider>
// );
