import { useState } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

// import Label from 'src/components/label';
import Iconify from '../../../component/iconify';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function AccountTableRow({
    selected,
    id,
    club_admin_id,
    name,
    club_admin_name,
    club_admin_tel,
    club_admin_status,
    updated,
    handleClick,
}) {
    const [open, setOpen] = useState(null);

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    return (
        <>
            <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        disableRipple
                        checked={selected}
                        onChange={handleClick}
                    />
                </TableCell> */}

                {/* <TableCell component="th" scope="row" padding="none">
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt={name} src={avatarUrl} />
                        <Typography variant="subtitle2" noWrap>
                            {name}
                        </Typography>
                    </Stack>
                </TableCell> */}

                <TableCell align="center">{id}</TableCell>
                <TableCell align="center">
                    {/* <Link to={`modify/${id}`}>{club_admin_id}</Link> */}
                    {club_admin_id}
                </TableCell>
                <TableCell align="center">{name}</TableCell>
                <TableCell align="center">{club_admin_name}</TableCell>
                <TableCell align="center">{club_admin_tel}</TableCell>
                <TableCell align="center">{club_admin_status === 0 ? '사용':'정지'}</TableCell>
                <TableCell align="center">{updated}</TableCell>
                {/* <TableCell align="right">
                    <IconButton onClick={handleOpenMenu}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell> */}
            </TableRow>

            {/* <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: { width: 140 },
                }}
            >
                <MenuItem onClick={handleCloseMenu}>
                    <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem
                    onClick={handleCloseMenu}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover> */}
        </>
    );
}

AccountTableRow.propTypes = {
    handleClick: PropTypes.func,
    name: PropTypes.any,
    selected: PropTypes.any,
    address: PropTypes.string,
    webpage: PropTypes.string,
    phone: PropTypes.string,
    courses: PropTypes.string,
    holes: PropTypes.number,
    updated: PropTypes.any,
};
