import {
    Card,
    Container,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    applyFilter,
    emptyRows,
    getComparator,
} from '../../golfInformation/view/utils';
import AccountTableToolbar from './account-table-toobar';
import AccountTableHead from './account-table-head';
import Scrollbar from '../../../component/scrollbar/scrollbar';
import AccountTableRow from './account-table-row';
import TableEmptyRows from '../../golfInformation/view/table-empty-rows';
import TableNoData from '../../golfInformation/view/table-no-data';

export default function GolfAcountView() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('id');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [filterField, setFilterField] = useState('name');

    const [me, setMe] = useState('');

    const [acounts, setAcounts] = useState([]);

    useEffect(() => {
        if (!localStorage.getItem('auth-token')) {
            navigate('/login');
        }
    });

    useEffect(() => {
        setMe(JSON.parse(localStorage.getItem('me')));
    }, []);

    useEffect(() => {
        if (me) {
            getAccounts(me).then((result) => {
                console.log(result);
                if (result.success) {
                    setAcounts(result.data);
                } else {
                    alert(result.message);

                    if (result.code === 1000) {
                        localStorage.clear();
                        navigate('/login');
                    }
                }
            });
        }
    }, [me, navigate]);

    const getAccounts = async (me) => {
        console.log('me : ', me);
        const params = {
            token: localStorage.getItem('auth-token'),
            user: me,
        };
        const lists = await axios.get(
            `${apiUrl}/club/admin/adminUsers`,
            { params }
        );
        console.log(lists);
        // const result = lists.data;
        return lists.data;
    };

    const handleSort = (event, id) => {
        const isAsc = orderBy === id && order === 'asc';
        if (id !== '') {
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(id);
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = acounts.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        console.log(event.target.value);
        setFilterName(event.target.value);
    };

    console.log(filterField);

    const dataFiltered = applyFilter({
        inputData: acounts,
        comparator: getComparator(order, orderBy),
        filterName,
        field: filterField,
    });

    console.log('dataFiltered : ', dataFiltered);

    const notFound = !dataFiltered.length && !!filterName;

    return (
        <Container maxWidth="true">
            <Typography variant="h4" sx={{ mt: 5, mb: 5 }}>
                골프장 계정 관리
            </Typography>
            <Card>
                <AccountTableToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                />
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }}>
                        <Table sx={{ minWidth: 800 }}>
                            <AccountTableHead
                                order={order}
                                orderBy={orderBy}
                                rowCount={acounts.length}
                                numSelected={selected.length}
                                onRequestSort={handleSort}
                                onSelectAllClick={handleSelectAllClick}
                                headLabel={[
                                    { id: 'id', label: 'ID' },
                                    { id: 'club_admin_id', label: '아이디' },
                                    { id: 'name', label: '골프장' },
                                    { id: 'club_admin_name', label: '담당자' },
                                    { id: 'club_admin_tel', label: '연락처' },
                                    { id: 'club_admin_status', label: '상태' },
                                    { id: 'updated_at', label: '최근접속일시' },
                                ]}
                            />
                            <TableBody>
                                {dataFiltered
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    ?.map((row) => (
                                        <AccountTableRow
                                            key={row.id}
                                            id={row.id}
                                            club_admin_id={row.club_admin_id}
                                            name={row.name}
                                            club_admin_name={
                                                row.club_admin_name
                                            }
                                            club_admin_tel={row.club_admin_tel}
                                            club_admin_status={
                                                row.club_admin_status
                                            }
                                            updated={row.updated_at}
                                        />
                                    ))}

                                <TableEmptyRows
                                    height={77}
                                    emptyRows={emptyRows(
                                        page,
                                        rowsPerPage,
                                        acounts.length
                                    )}
                                />

                                {notFound && <TableNoData query={filterName} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    page={page}
                    component="div"
                    count={acounts.length}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[50, 100, 200]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
        </Container>
    );
}
