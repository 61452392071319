import { useCallback, useState } from 'react';
import useAxios from './use-axios';

const useFetchData = (baseURL) => {
    const { request, loading, error } = useAxios(baseURL);
    const [data, setData] = useState([]);

    const fetchData = useCallback(
        (url, params = {}, onSuccess) => {
            request({
                method: 'get',
                url,
                params,
                onSuccess: (response) => {
                    if (response.success) {
                        setData(response.data);
						if (onSuccess) onSuccess(response.data);
                    } else {
                        alert(response.message || '데이터를 불러오는 중 오류가 발생했습니다.');
                    }
                },
                onError: (err) => {
                    alert(`Error: ${err.message}`);
                },
            });
        },
        [request]
    );

    return { data, loading, error, fetchData };
};

export default useFetchData;
