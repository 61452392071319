export function formatNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function parseNumber(formattedNumber) {
    return formattedNumber.toString().replace(/,/g, '');
}

export function commaNumber(value) {
    return formatNumber(parseNumber(value.replace(/[^\d,]/g, '')));
}
